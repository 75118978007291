.canvas {
  touch-action: none;
  width: 100%;
  height: 100%;
}

.canvas:focus-visible {
  outline: none;
}

.loaderContainer {
  z-index: 20;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.loaderContainerFaded {
  opacity: 0;
}

.loaderCenter {
  display: inline-block;
  width: 250px;
  margin: -25%;
}

.loaderLogo {
  display: block;
  width: 130px;
  height: auto;
  margin: 0 auto;
}

.loaderBarContainer {
  display: block;
  position: relative;
  height: 6px;
  background-color: #444;
}

.loaderBarProgress {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background-color: orange;
  transition: width 0.3s ease-in-out;
}

.introContainer {
  display: block;
  position: fixed;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
  height: 30px;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.introFading {
  opacity: 0;
}

/**
* icona di posizionamento della scena che va da sinistra a destra
*/
.introIcon {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  animation: intro-animation 5s infinite ease-in-out;
}

@keyframes intro-animation {
  0% {
    left: 25%;
  }
  50% {
    left: 75%;
  }
  100% {
    left: 25%;
  }
}
